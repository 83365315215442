:root {
    --yellow: #f2c94c;
    --success: #27ae60;
    --absolut-black: #000;
    --background: #ffffff;
    --absolut-white: #fff;
    --main-color-primary: #FF8001;
    --main-color-secendery: #5867dd;
    --main-color-green: #2ec16b;
    --main-color-red: #f13e3e;
    --main-color-gray: #999797;
    --main-color-orange: #ff7300;
    --main-color-yellow: #f2c94c;
    --main-color-blue: #00acd3;
    --main-color-purple: #6200ee;
    --color-secendery-01: #f7f7fd;
    --color-secendery-02: #ebedfb;
    --color-secendery-04: #a3abec;
    --color-secendery-06: #5867dd;
    --color-secendery-03: #cdd1f5;
    --color-secendery-07: #4d5ac8;
    --color-secendery-05: #7985e4;
    --color-secendery-08: #404ca6;
    --color-secendery-10: #ebedfb;
    --color-secendery-09: #2e367b;
    --color-secendery-11: #0d0f22;
    --color-gray-01: #fafafa;
    --color-gray-02: #f5f5f5;
    --color-gray-03: #eee;
    --color-gray-04: #e0e0e0;
    --color-gray-05: #bdbdbd;
    --color-gray-06: #999797;
    --color-gray-07: #757575;
    --color-gray-08: #525252;
    --color-gray-09: #fafafa;
    --color-gray-10: #212121;
    --color-gray-11: #121212;
    --color-primary-01: #f8fcf4;
    --color-primary-02: #eff8e6;
    --color-primary-04: #b0da80;
    --color-primary-03: #d7ecbf;
    --color-primary-05: #95cd55;
    --color-primary-07: #67a125;
    --color-primary-06: #FF8001;
    --color-red-01: #fef5f5;
    --color-primary-09: #3a5c13;
    --color-primary-11: #101a05;
    --color-primary-08: #51801d;
    --color-primary-10: #283f0e;
    --menu-menu-white: #fff;
    --menu-menu-10: #212121;
    --menu-menu-07: #757575;
    --menu-menu-09: #3b3b3b;
    --menu-menu-black: #000;
    --menu-menu-01: #fafafa;
    --menu-menu-06: #999797;
    --color-red-02: #fde8e8;
    --menu-menu-04: #e0e0e0;
    --color-red-03: #fbc5c5;
    --color-red-05: #f46565;
    --color-red-04: #f79595;
    --color-red-06: #f13e3e;
    --color-red-07: #c82c2c;
    --color-red-08: #aa2121;
    --color-red-09: #811a1a;
    --color-red-10: #581313;
    --color-red-11: #2c0909;
    --color-green-01: #fafdfb;
    --color-green-03: #d5f3e1;
    --color-green-02: #eaf9f0;
    --color-green-05: #62d190;
    --color-green-06: #2ec16b;
    --color-green-07: #249e57;
    --color-green-04: #a1e3bc;
    --color-green-08: #1d7d45;
    --color-green-09: #155f34;
    --color-green-10: #0f3d22;
    --color-green-11: #061c0f;
    --color-yellow-01: #fefcf6;
    --color-yellow-02: #fdf7e4;
    --color-yellow-03: #fbefc9;
    --color-yellow-05: #f5d470;
    --color-yellow-06: #f2c94c;
    --color-yellow-04: #f8e19d;
    --color-yellow-07: #dbae24;
    --color-yellow-09: #846917;
    --color-yellow-10: #4e3c06;
    --color-blue-01: #f2fbfd;
    --color-blue-02: #e5f7fb;
    --color-yellow-08: #b99116;
    --color-blue-04: #73d1e7;
    --color-blue-03: #b2e6f2;
    --color-blue-05: #33bddc;
    --color-blue-06: #00acd3;
    --color-yellow-11: #221a02;
    --color-blue-07: #0096b8;
    --color-blue-08: #00718a;
    --color-blue-09: #005164;
    --color-blue-11: #001b21;
    --color-blue-10: #003642;
    --color-orange-01: #fff8f2;
    --color-orange-02: #ffead9;
    --color-orange-03: #ffd5b2;
    --color-orange-04: #ffb273;
    --color-orange-05: #ff8f33;
    --color-orange-06: #ff7300;
    --color-orange-07: #db6300;
    --color-orange-08: #bd5500;
    --color-orange-09: #883d00;
    --color-orange-10: #4f2400;
    --color-orange-11: #281200;
    --color-purple-02: #efe5fd;
    --color-purple-01: #f7f2fe;
    --color-purple-03: #d0b2fa;
    --color-purple-04: #a973f6;
    --color-purple-05: #8133f1;
    --color-purple-06: #6200ee;
    --color-purple-07: #5700d2;
    --color-purple-08: #4e00be;
    --color-purple-09: #3a008c;
    --color-purple-10: #220053;
    --color-purple-11: #13002e;
    --color-guidance-red-11: #2a0508;
    --color-guidance-red-01: #fff7f7;
    --color-guidance-green-11: #06130b;
    --absolute-white: #fff;
    --color-guidance-gray-11: #121212;
    --color-guidance-green-01: #fafdfb;
    --surface-overlay: rgba(33, 33, 33, 0.08);
    --primary: rgba(105, 168, 42, 0.9);
    --primary-20: rgba(105, 168, 42, 0.2);
    --red: #eb5757;
    --sys-light-surface-1: #000;
    ;
}