:root {
    --xxxs: 4px;
    --xxs: 8px;
    --xs: 12px;
    --s: 16px;
    --m: 24px;
    --l: 32px;
    --xl: 40px;
    --xxl: 48px;
    --xxxl: 56px;
    --xx-l: 60px;
    --xx-2l: 100px;

}