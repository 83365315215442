/* You can add global styles to this file, and also import other style files */
@import "assets/typeography/font-faces.scss";
@import "assets/styles/breakpoints.scss";
@import "assets/typeography/heading.css";
@import "assets/styles/variables.scss";
@import "./assets/styles/spacing.css";
@import "./assets/styles/colors/colors";
@import "./assets/styles/colors/dark_colors";
@import "./assets/styles/colors/light_colors";
@import "./assets/styles/custome-material.scss";

body {
    box-sizing: border-box;
    margin: 0;
    background-color: var(--background);
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}