// Bold
@font-face {
    font-family: roboto_CG;
    src: url("./../fonts/roboto/Roboto-Medium-webfont.woff");
    src: url("./../fonts/roboto/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype") url("./../fonts/roboto/Roboto-Medium-webfont.woff") format("woff");
    font-weight: 500;
}

// Regular
@font-face {
    font-family: roboto_CG;
    src: url("./../fonts/roboto/Roboto-Regular-webfont.woff");
    src: url("./../fonts/roboto/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype") url("./../fonts/roboto/Roboto-Regular-webfont.woff") format("woff");
    font-weight: 400;
}


@font-face {
    font-family: roboto_CG;
    src: url("./../fonts/roboto/Roboto-Light-webfont.woff");
    src: url("./../fonts/roboto/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype") url("./../fonts/roboto/Roboto-Light-webfont.woff") format("woff");
    font-weight: 300;
}

// italic
@font-face {
    font-family: roboto_CG;
    src: url("./../fonts/roboto/Roboto-Italic-webfont.woff");
    src: url("./../fonts/roboto/Roboto-Italic-webfont.eot?#iefix") format("embedded-opentype") url("./../fonts/roboto/Roboto-Italic-webfont.woff") format("woff");
    font-weight: 200;
}