:root {
    --border-color: #eeeeee;
    --primary-1: #FF8001;
    --primary-2: #4a761d;
    --primary-3: #e5f3d5;
    --secondry-1: #5867dd;
    --secondry-2: #f7f7fd;
    --error-error: #eb5757;
    --gray-white: #fff;
    --gray-50: #ffffff;
    --gray-100: #f5f5f5;
    --gray-200: #eee;
    --gray-300: #e0e0e0;
    --gray-400: #bdbdbd;
    --gray-500: #9e9e9e;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-900: #212121;
    --gray-950: #fafafa;
    --gray-800: #424242;
    --gray-black: #000;
    --error-error-5: rgba(235, 87, 87, 0.05);
    --status-rejected-faild-expire-request: #db2929;
    --status-rejected-faild-expire-request-10: rgba(219, 41, 41, 0.1);
    --status-accepted-done-success-verified-10: rgba(0, 162, 103, 0.1);
    --status-on-hold-pending-new: #ff7300;
    --status-accepted-done-success-verified: #00a267;
    --status-on-hold-pending-new-10: rgba(255, 115, 0, 0.1);
    --status-my-reply: #1976d2;
    --status-open-10: rgba(98, 0, 238, 0.1);
    --status-processing-10: rgba(6, 140, 171, 0.1);
    --status-processing: #068cab;
    --status-open: #6200ee;
    --status-my-reply: rgba(25, 118, 210, 0.1);
    --status-payment-paid: #5867dd;
    --status-paying: #00acd3;
    --status-payment-paid-10: rgba(88, 103, 221, 0.1);
    --status-blocked-deleted: #598296;
    --status-blocked-deleted-10: rgba(89, 130, 150, 0.1);
    --status-paying-10: rgba(0, 172, 211, 0.1);
    --status-faild-expire-token: #795548;
    --status-refund: #ac37b9;
    --status-faild-expire-token-10: rgba(121, 85, 72, 0.1);
    --status-accepted: #8e4e85;
    --status-incomplete-cancel-10: rgba(100, 114, 140, 0.1);
    --status-incomplete-cancel: #64728c;
    --status-archived: #9da4b4;
    --status-accepted-10: rgba(142, 78, 133, 0.1);
    --status-closed-10: rgba(50, 73, 111, 0.1);
    --tatus-closed: #32496f;
    --status-refund-10: rgba(172, 55, 185, 0.1);
    --success: #36d277;
    --notice-10: rgba(245, 215, 124, 0.1);
    --notice: #f5d77c;
    --success-10: rgba(54, 210, 119, 0.1);
    --error-error-text: #f13e3e;
    --sys-light-background: #fafafa;
    --sys-light-surface-1: #fff;
    --color-gray-6: #999797;
}