.mat-mdc-text-field-wrapper {
    // height: 48px !important;
    border: 1px solid var(--border-color) !important;
}

.mdc-notched-outline__notch {
    border-top: 1px solid var(--border-color) !important;
    border-bottom: 1px solid var(--border-color) !important;
}

.mdc-notched-outline__leading {
    border-left: 1px solid var(--border-color) !important;
    border-top: 1px solid var(--border-color) !important;
    border-bottom: 1px solid var(--border-color) !important;
}

.mdc-notched-outline__trailing {
    border-top: 1px solid var(--border-color) !important;
    border-bottom: 1px solid var(--border-color) !important;
    border-right: 1px solid var(--border-color) !important;
}

.mat-mdc-select-panel {
    background-color: var(--gray-50) !important;
}

.option-items {
    background-color: var(--gray-50);
    color: var(--color-gray-11) !important;

    .drp-item {
        display: flex;
        align-items: center;

        img {
            width: 20px;
        }
    }

    .drp-items-info {
        label:first-child {
            font-size: 16px !important;
            font-weight: 500 !important;
        }

        label:not(first-child) {
            font-size: 12px;
            font-weight: normal;
        }
    }

    &:hover {
        background-color: var(--gray-950) !important;
    }

    &:active {
        background-color: var(--gray-950) !important;
    }

    .mat-mdc-option-active {
        background-color: var(--gray-950) !important;

    }
}

mat-icon {
    cursor: pointer;
}