:root {
    .dark-mode {
        --background: #212121;
        --border-color: #212121;
        --primary-1: #FF8001;
        --primary-2: #639f27;
        --primary-3: #293e12;
        --secondry-1: #6d7be1;
        --secondry-2: #24242a;
        --success: #2cc36b;
        --error-error: #ee6e6e;
        --gray-darkest: #0d0d0d;
        --color-gray-11: #fafafa;
        --gray-50: #121212;
        --gray-100: #161616;
        --gray-200: #1e1e1e;
        --gray-300: #2c2c2c;
        --gray-400: #424242;
        --gray-500: #616161;
        --gray-600: #bdbdbd;
        --gray-700: #9e9e9e;
        --gray-800: #bdbdbd;
        --gray-900: #fafafa;
        --gray-950: #3b3b3b;
        --gray-black: #fff;
        --gray-white: #000;
        --color-gray-6: #999797;
        --error-error-5: rgba(238, 110, 110, 0.05);
        --status-accepted-done-success-verified-10: rgba(0, 188, 119, 0.1);
        --status-accepted-done-success-verified: #00bc77;
        --status-rejected-faild-expire-request-10: rgba(223, 63, 63, 0.1);
        --status-rejected-faild-expire-request: #df3f3f;
        --status-on-hold-pending-new: #ff811a;
        --status-on-hold-pending-new-10: rgba(255, 129, 26, 0.1);
        --status-processing: #07a1c4;
        --status-processing-10: rgba(7, 161, 196, 0.1);
        --status-open-10: rgba(148, 71, 255, 0.1);
        --status-open: #6f09ff;
        --status-my-reply-10: rgba(32, 130, 228, 0.1);
        --status-my-reply: #2082e4;
        --status-blocked-deleted: #598296;
        --status-payment-paid: #6d7be1;
        --status-blocked-deleted-10: rgba(89, 130, 150, 0.1);
        --status-payment-paid-10: rgba(109, 123, 225, 0.1);
        --status-paying: #00c1ed;
        --status-paying-10: rgba(0, 193, 237, 0.1);
        --status-refund: #ba43c7;
        --status-refund-10: rgba(186, 67, 199, 0.1);
        --status-faild-expire-token: #795548;
        --status-accepted: #8e4e85;
        --status-accepted-10: rgba(167, 106, 158, 0.1);
        --status-incomplete-cancel: #64728c;
        --status-incomplete-cancel-10: rgba(100, 114, 140, 0.1);
        --status-closed: #3a5581;
        --status-closed-10: rgba(58, 85, 129, 0.1);
        --status-archived: #9da4b4;
        --status-faild-expire-token-10: rgba(155, 106, 88, 0.1);
        --notice: #f4d064;
        --notice-10: rgba(244, 208, 100, 0.1);
        --success: #2cc36b;
        --success-10: rgba(44, 195, 107, 0.1);
        --error-error-text: #f35656;
        --notice: #f4d064;
        --sys-background: #121212;
        --sys-surface-1: #212121;
        --color-secendery-10: #1f2451;
    }
}